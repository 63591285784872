<script setup>
import syButton from '../../components/syButton/index.vue'
import { ref } from 'vue'
import { useRouter } from 'vue-router'
const router = useRouter()
// api
import { subscriptionApi } from '@/api/global'

// global js
import {toPath, toTop} from "@/utils/global";
import { ElMessage } from "element-plus";
//引用i18n
import { useI18n } from 'vue-i18n'
const { t } = useI18n()


// 订阅
const email = ref('')
const handleSubscription = async () => {
  if(email.value){
    let reg = /^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/
    if (reg.test(email.value)) {
      let { messageCode } = await subscriptionApi(email.value)
      if (messageCode == 3010) {
        ElMessage.success(t(`langCode.${messageCode}`))
        email.value = ''
      } else {
        ElMessage.warning(t(`langCode.${messageCode}`))
      }
    } else {
      ElMessage.warning(t(`tips.emailError`))
    }
  }else{
    ElMessage.warning(t(`tips.emailNull`))
  }
}
</script>

<template>
  <div class="footer pb-5">
    <div class="circle">
      <div class="circle_top"></div>
      <div class="toTop" @click="toTop">
        <img src="../../assets/images/toTop.png" alt="">
      </div>
    </div>
    <div class="container">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 col-lg-5 col-xl-6 mb-3 mb-lg-0 d-flex align-items-center">
            <div class="logo col-4 col-lg-6">
              <img src="../../assets/images/logo.png" alt="">
            </div>
          </div>
          <div class="col-12 col-lg-7 col-xl-6 d-flex">
            <div class="subscription w100">
              <span class="wt d-none d-sm-block">{{ $t('g.subscribeToUs') }}</span>
              <input v-model="email" type="text" :placeholder="$t('serve.email')" @keydown.enter.prevent="handleSubscription" />
              <syButton class="contactBtn" @click="handleSubscription">{{ $t('g.subscribeNow') }} <i class="iconfont icon-fangxiang2-youbian"></i></syButton>
            </div>
          </div>
        </div>
        <div class="row mt-3 mb-2 mb-md-4 mt-md-4">
          <div class="text bold700 col-12 col-md-6 d-flex flex-wrap lh-lg">
            <div class="bold700 d-flex align-items-center me-3 telphone">
              <img src="@/assets/images/home/phone.png" alt="" class="me-2">
              <a href="tel:852 2491 5908">+852 2491 5908</a>
            </div>
            <div class="bold700 d-flex align-items-center email">
              <img src="@/assets/images/home/email.png" alt="" class="me-2">
              <a href="mailto:info@baculturemedia.com">info@baculturemedia.com</a>
            </div>
          </div>
          <div class="share col-12 col-md-6 mt-2">
            <div class="item"><a target="_blank" href="https://www.facebook.com/BACulture.media"><img
                src="../../assets/images/footer/facebook.png" alt=""></a></div>
            <div class="item"><a target="_blank" href="https://www.instagram.com/bayareaculture.media/"><img
                src="../../assets/images/footer/instagram.png" alt=""></a></div>
            <div class="item"><a target="_blank" href="https://api.whatsapp.com/send?phone=85293452889"><img
                src="../../assets/images/footer/whatsapp.png" alt=""></a></div>
            <div class="item"><a target="_blank" href="https://www.youtube.com/channel/UC9R3cbFQN-Cf-NpjDAvvKxw"><img
                src="../../assets/images/footer/videoimg.png" alt=""></a></div>
            <div class="item"><a target="_blank"
                                 href="https://baculturemedia.com/wp-content/uploads/2020/07/wechat_qrcode.png"><img
                src="../../assets/images/footer/wechat.png" alt=""></a></div>
            <div class="item">
              <a target="_blank"
                 href="https://weibo.com/p/1005057231814065?from=qrcode&frwbqr=53&jumpfrom=weibocom&luicode=10000011&lfid=2302837231814065_-_INFO&containerid=1005057231814065&is_all=1 "><img
                  src="../../assets/images/footer/weibo.png" alt=""></a>
            </div>
          </div>
        </div>

        <div class="declare">
          <div class="left">© 2023. Powered By GBAEasy.</div>
          <div class="right" @click="toPath('/noticeThat')">
            <div>{{ $t('g.clause') }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.iconfont {
  margin-left: 5px;
}

.wt {
  font-weight: bold;
  width: 90px;
}

::v-deep .is-light {
  width: 400px !important;
}

.footer {
  background: #106A88;
  text-align: left;
  color: #fff;
  position: relative;
  .email a:hover,.telphone a:hover{
    text-decoration: underline!important;
  }

  .container {
    padding: 0;
  }

  .circle {
    width: 100%;
    height: 200px;
    overflow: hidden;
    position: relative;
    background: #106A88;
    display: flex;
    justify-content: center;

    &:after {
      width: 106%;
      height: 200px;
      position: absolute;
      left: -3%;
      top: -60px;
      content: '';
      border-radius: 0 0 50% 50%;
      background: #f7fcfc;
      z-index: 1;
    }

    .toTop {
      width: 100px;
      margin: auto;
      z-index: 2;
      position: absolute;
      cursor: pointer;
      bottom: 15px;
    }
  }

  .footer-cont {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &:after {
      content: '';
      width: 100%;
      height: 1px;
      background: rgba(255, 255, 255, 0.5);
    }

    .left,
    .right {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .declare {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    height: 60px;
    line-height: 60px;
    font-size: 16px;

    &:after, &:before {
      content: '';
      width: 100%;
      height: 1px;
      background: rgba(255, 255, 255, 0.5);
    }

    .right {
      cursor: pointer;
    }

    .right :hover {
      text-decoration: underline;
    }
  }

  .subscription {
    display: flex;
    align-items: center;
    justify-content: end;
    position: relative;

    input {
      width: 100%;
      height: 84px;
      border: none;
      outline: none;
      border-radius: 10px;
      background: rgba(255, 255, 255, 0.1);
      padding: 10px;
      color: #fff;
    }

    button {
      color: #000;
      background: #6ACDC6;
      border: none;
      margin-top: 0;
      font-weight: 500;
      border-radius: 10px;
      position: absolute;
      right: 8px;
      cursor: pointer;
    }
  }

  .share {
    display: flex;
    justify-content: end;
    align-items: center;

    .item {
      width: 40px;
      margin-left: 25px;
    }
  }

  .text {
    a {
      color: #fff;
    }

    img {
      width: 25px;
    }
  }
}

@media only screen and (max-width: 992px) {
  .footer-cont {
    flex-direction: column;

    .left,
    .right {
      width: 100% !important;
      margin-bottom: 20px;
      cursor: pointer;
    }
  }

  .footer .subscription input{
    height: 64px;
  }

  .footer {
    .circle .toTop {
      width: 70px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .footer {
    .footer-cont {
      .left {
        margin-bottom: 0px;
      }
      .right{
        margin-bottom: 0px;
      }
    }

    // 圈圈调整
    .circle {
      width: 100%;
      height: 80px;
      overflow: hidden;
      position: relative;
      background: #106A88;
      display: flex;
      justify-content: center;

      &:after {
        width: 100%;
        height: 110px;
        position: absolute;
        left: 0%;
        top: -60px;
        content: '';
        border-radius: 0 0 27% 27%;
        z-index: 1;
      }

      .toTop {
        width: 45px;
        margin: auto;
        z-index: 2;
        position: absolute;
        cursor: pointer;
        bottom: 5px;
      }
    }
    .text {
      display: flex;

      .mb-2 {
        margin-bottom: 0 !important;
      }
    }

    // 分享图标调整
    .share {
      display: flex;
      justify-content: end;
      align-items: center;

      .item {
        width: 24px;
        margin: 10px 0 10px 8px;
      }
    }

    .declare {
      font-size: 11px;
      line-height: 40px;
      height: 22px;
    }
  }

}</style>
