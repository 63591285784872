import request from "@/utils/request";

// 获取当前账户添加的香港公司信息
export function getHKCompanies() {
  return request({
    url: `/company/getHKCompanies`,
    method: "get",
  });
}

// 保存香港公司信息
export function storageHKCInformation(data) {
  return request({
    url: `/company/storageHKCInformation`,
    method: "post",
    data,
  });
}

// 提交香港公司信息
// export function addHKC(data) {
//   return request({
//     url: `/company/addHKC`,
//     method: "post",
//     data,
//   });
// }

// 获取香港公司列表
export function getHKCList(params) {
  return request({
    url: `/company/getHKCList`,
    method: "get",
    params,
  });
}

//获取香港公司详情
export function getHKCDetails(hkcId) {
  return request({
    url: `/company/getHKCDetails/${hkcId}`,
    method: "get",
  });
}

//添加香港公司
export function addHKC(data) {
  return request({
    url: "/company/addHKC",
    method: "post",
    data,
  });
}

//编辑香港公司
export function updateHKCInfo(data) {
  return request({
    url: "/company/updateHKCInfo",
    method: "put",
    data,
  });
}
//香港狀態
export function updateCompanyStatus(data) {
  return request({
    url: "/hk/updateCompanyStatus",
    method: "put",
    data,
  });
}
