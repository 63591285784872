import request from "@/utils/request";

// 获取图片上傳地址
export function getUploadUrl(url, params) {
  return request({
    url,
    method: "get",
    params,
  });
}

//获取查看路径
export function showImageUrl(keyName) {
  return request({
    url: "/file/getFilePath",
    method: "get",
    params: { keyName },
  });
}

// 添加聯絡我們信息
export function addContactInfo(data) {
  return request({
    url: `/message/contact/add`,
    method: "post",
    data,
  });
}

//获取所有標簽父级列表
export function getTypeList(params) {
  return request({
    url: `/public/labelCategory/getAListOfAllParents`,
    method: "get",
    params,
  });
}

//获取所有内地公司列表
export function getNdgsList() {
  return request({
    url: `/company/getLoginUserMC`,
    method: "get",
  });
}

//获取標簽子级列表
export function getTypeChildren(parentId) {
  return request({
    url: `/public/labelCategory/getTheSubsetListByTheTagParentId?parentId=${parentId}`,
    method: "get",
  });
}

//添加聯絡我們信息
export function addContact(data) {
  return request({
    url: `/public/contactus/addContactUs`,
    method: "post",
    data,
  });
}

//订阅
export function subscriptionApi(email) {
  return request({
    url: `/public/contactus/subscription`,
    method: "post",
    data: { email },
  });
}

//获取当前用户上傳地址

export function getUserAvatarUploadFile(params) {
  return request({
    url: `/file/getUserAvatarUploadFile`,
    method: "get",
    params,
  });
}

//修改用户
export function updateUserName(data) {
  return request({
    url: `/updateUserName`,
    method: "put",
    data,
  });
}

export function getCurrentUserInformation() {
  return request({
    url: `/getCurrentUserInformation`,
    method: "get",
  });
}

//落户列表
export function caseList(type, params) {
  return request({
    url: `/public/case/list/${type}`,
    method: "get",
    params,
  });
}
//落户详情
export function caseDetails(id) {
  return request({
    url: `/public/case/details/${id}`,
    method: "get",
  });
}
//落户所有列表
export function caseDetailsList(type) {
  return request({
    url: `/public/case/detailsList/${type}`,
    method: "get",
  });
}
