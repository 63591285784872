module.exports = {
    // 消息提示码
    langCode: {
        '1001': '請輸入密碼',
        '1002': '請輸入郵箱',
        '1003': '電郵格式不正確,請輸入正確電郵地址',
        '1004': '當前用戶已存在',
        '1005': '不能夠將Admin全部刪除',
        '1007': '超級管理員不能被刪除',
        '1008': '此管理員不存在或已被禁用',
        '1009': '登錄成功',
        '1010': '賬號密碼錯誤,或郵箱未驗證',
        '1011': '請輸入暱稱！',
        '1012': '用戶名格式不正確！',
        '1013': '請輸入姓名！',
        '1014': '請輸入電郵地址！',
        '1015': '電郵格式不正確,請輸入正確電郵地址！',
        '1016': '請輸入密碼',
        '1017': '請輸入8位或以上字元的密碼！',
        '1018': '請輸入電話號碼！',
        '1019': '電話號碼格式不正確！',
        '1020': '名稱已經有用戶選取，請註冊另一個名稱',
        '1021': '註冊成功',
        '1022': '註冊失敗',
        '1023': '電郵已經有用戶選取，請註冊另一個電郵',
        '1024': '登錄失敗',
        '1025': '令牌為空',
        '1026': 'token 資訊驗證失敗',
        '1027': 'token 已過期或驗證不正確！',
        '1028': 'refreshToken 無效',
        '1029': '請輸入賬號或密碼',
        '1030': '退出成功',
        '1031': '退出失敗',
        '1032': '用戶未登錄',
        '1033': 'accessToken 無效',
        '1034': '您沒有權限訪問',
        '1035': '請填寫表格必要內容',
        '1036': '不能禁用當前admin',
        '1037': '不能刪除當前admin',
        '1038': '權限變更請重新登陸',
        '1039': '用戶不存在',
        '1040': '重置密碼鏈接已過期',

        '2001': '香港公司不存在',
        '2002': '公司不存在',
        '2003': '公司名稱已存在',

        '3001': '發送成功',
        '3002': '發送失敗',
        '3003': '請輸入姓氏',
        '3004': '請輸入名字',
        '3005': '請輸入郵箱地址',
        '3006': '電郵格式不正確,請輸入正確電郵地址',
        '3007': '請選擇行業',
        '3008': '請勾選或取消訂閱最新咨詢選項',
        '3009': '請選擇您是否是會員',
        '3010': '訂閱成功',
        '3011': '訂閱失敗',

        '4001': '當前用戶或活動不存在',
        '4002': '簽到成功',
        '4003': '簽到失敗',
        '4004': '當前活動已經報名',
        '4005': '報名成功',
        '4006': '報名人數已爆滿',

        '5001': '郵件已發送',
        '5002': '用戶正在修改密碼',
        '5003': '發送失敗',
        '5004':	'发送失败!',
        '5005':	'請前往您的郵箱驗證!',
        '5006':	'驗證链接已过期!',
        '5007':	'驗證成功！',
        '5008':	'驗證失败！',
        '5009':	'已发送注册驗證信息，請前往郵箱查看！',

        '6001': '分配成功',
        '6002': '分配失敗',
        '6003': '確認成功',
        '6004': '確認失敗',
        '6005': '續費成功',
        '6006': '續費失敗',
        '6007': '沒有權限',
        '6008': '當前服務不可用',
        '6009': '服務已被使用或已進入註冊流程',
        '6010': '當前服務已被使用',
        '6011': '當前還沒有分配合作商戶',
        '6012': '服務已加入購物車',

        '7001': '添加成功',
        '7002': '添加失敗',
        '7003': '刪除成功',
        '7004': '刪除失敗',
        '7005': '修改成功',
        '7006': '修改失敗',
        '7007': 'token已失效',
        '7008': '上傳成功',
        '7009': '上傳失敗',
        '7010': '操作成功',
        '7011': '操作失敗',
        '7012': '提交成功',
        '7013': '提交失敗',
        '7014': '訂單創建成功',
        '7015': '當前沒有選擇下單項目',

        '8001': '當前父節點不存在',
        '8002': '請輸入必要內容',
        '8003': '標籤不存在',
        '8004': '當前標籤下還有其他子標籤，刪除失敗！',
        '8005': '當前標籤名字重複',
    },
    g: {
        home: '首頁',
        login: '登錄',
        about: '關於我們',
        serve: '服務',
        news: '最新消息',
        problem: '常見問題',
        search: '搜索',
        zh: '中文簡體',
        ft: '中文繁體',
        en: '英文',
        notice: '通知',
        activity: '活動動態',
        subscribeToUs: '訂閲我們',
        subscribeNow: '立即訂閱',
        email: '電郵地址',
        clause: '隱私權政策條款及細則',
        clause2: '服務協議-條款及細則',
        back: '返回',
        sure: '確認',
        cancel: '取消',
        WStips: '溫馨提示',
        view: '查看',
        none: '暫無',
    },
    // 提示词
    tips: {
        selectImg: '選擇本地圖片',
        emailNull: '請輸入郵箱地址',
        emailError: '電郵格式不正確,請輸入正確電郵地址',
    },
    // 首页
    home: {

    },
    com: {
        uploading: '上傳'
    },
    // 側邊欄和頂部
    top: {
        backToHome: '回到主頁',
        logOut: '退出登錄',
        welcome: '歡迎使用本系統',
        system: '灣區易',
        login: '登入',
        loginFailure: '登錄已失效',
        reLogin: '重新登錄',
        myInfo: '個人資訊',
        addMC: '新增内地公司',
        mcList: '内地公司列表',
        addHKC: '新增香港公司',
        hkList: '香港公司列表',
        supplierLink: '开通供应商入口',
        partnerLogin: '合作商戶登入',
        signRecord: '活動報名記錄',
        shoppingCart: '購物車',
    },

    // 服务相关
    serve:{
        title: '一站式大灣區企業服務',
        agreeC: '同意並繼續',

    },

    // login
    lg: {
        consult: '個人咨詢',
    }
}
