import hkCompany from "@/views/hkCompany";
import progressForm from "@/views/hkCompany/progressForm";
import hkCompanyList from "@/views/hkCompany/list";
import hkCompanyDetail from "@/views/hkCompany/detail";

export default {
    path: "/hkCompany",
    name: "hkCompany",
    // meta: { title: '香港公司' },
    component: () => import("@/views/hkCompany"),
    children: [
        // 添加香港公司
        {
            name: "addHkCompany",
            path: "addHkCompany",
            component: () => import("@/views/hkCompany/progressForm"),
        },
        // 香港公司列表
        {
            name: "hkCompanyList",
            path: "hkCompanyList",
            component: () => import("@/views/hkCompany/list"),
        },
        // 香港公司详情
        {
            name: "hkCompanyDetail",
            path: "hkCompanyDetail",
            component: () => import("@/views/hkCompany/detail"),
        },
    ]
}

