import request from '@/utils/request'

// 图片上傳
export function uploadApi(url, data) {
    return request({
        url,
        method: 'post',
        data
    })
}

//登录
export function login(data) {
    return request({
        url: '/login',
        method: 'post',
        data
    })
}
// 谷歌access token 获取用户信息
export function googleLogin(accessToken) {
    return request({
        url: `/googleLogin/${accessToken}`,
        method: 'post'
    })
}
// facebook access token 获取用户信息
export function fbLogin(accessToken) {
    return request({
        url: `/fbLogin/${accessToken}`,
        method: 'post'
    })
}

// 谷歌&facebook登錄后，完善信息
export function supplementaryInformation(data) {
    return request({
        url: `/supplementaryInformation`,
        method: 'post',
        data
    })
}



//退出登录
export function logout(data) {
    return request({
        url: '/userLogout',
        method: 'post'
    })
}

//忘记密码 发送重置密码邮件
export function sendEmail(data) {
    return request({
        url: '/public/mail/resetPasswordSendEmail',
        method: 'post',
        data
    })
}

// 用户注册
export function registUser(data) {
    return request({
        url: '/register',
        method: 'post',
        data
    })
}

// 验证注册邮箱
export function verifyRegistration(token) {
    return request({
        url: `/verifyRegistration/${token}`,
        method: 'get'
    })
}

// 重设密码
export function resetPassword(data) {
    return request({
        url: '/resetPasswords',
        method: 'put',
        data
    })
}
