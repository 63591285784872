
export default {
    path: "/qyzxgw",
    name: "qyzxgw",
    redirect: "/qyzxgw/progressList",
    // meta: { title: '企業咨詢顧問' },
    component: () => import("@/views/serve/qyzxgw"),
    children: [
        // 同意须知
        {
            path: "agreen",
            name: "qyzxgwAgreen",
            component: () => import("@/views/serve/qyzxgw/agreen"),
            // meta: { title: '服務協議' }
        },
        // 注册步骤
        {
            path: "progressForm",
            name: "qyzxgwProgressForm",
            component: () => import("@/views/serve/qyzxgw/progressForm"),
        },
        // 进度列表
        {
            path: "progressList",
            name: "qyzxgwProgressList",
            component: () => import("@/views/serve/qyzxgw/progressList"),
        },
    ]
}

