
export default {
    path: "/mainland",
    name: "mainland",
    redirect: "/mainland/progressList",
    // meta: { title: '內地公司註冊' },
    component: () => import("@/views/serve/mainland"),
    children: [
        // 同意须知
        {
            path: "agreen",
            name: "agreen",
            component: () => import("@/views/serve/mainland/agreen"),
            // meta: { title: '服務協議' }
        },
        // 注册步骤
        {
            path: "registStep/:orderId/:companyId",
            name: "registStep",
            component: () => import("@/views/serve/mainland/registStep"),
        },
        // 进度列表
        {
            path: "progressList",
            name: "progressList",
            component: () => import("@/views/serve/mainland/progressList"),
        },
        // 服务详情
        {
            path: "detail",
            name: "mainlandDetail",
            component: () => import("@/views/serve/mainland/progressList/detail"),
        },
        // 最后一部表单确认
        {
            path: "mainlandProfile/:orderId/:companyId",
            name: "mainlandProfile",
            component: () => import("@/views/serve/mainland/profile"),
        },
    ]
}

