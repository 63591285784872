
export default {
  path: "/case",
  name: "case",
  component: () => import("@/views/caseAssemble"),
  // meta: { title: '成功案例' },
  children: [
    {
      name: "caseList",
      path: "caseList",
      component: () => import("@/views/caseAssemble/list"),
    },
    {
      name: "caseDetails",
      path: "caseDetails",
      component: () => import("@/views/caseAssemble/details"),
    },
  ],
};
