import activity from "@/router/activity";

export const companyStatus = [
  { value: '0', label: '禁用', type: 'danger' },
  { value: '1', label: '正常', type: '' },
  { value: '2', label: '未通過', type: 'info' },
  { value: '3', label: '申請中', type: 'success' },
  { value: '4', label: '審核中', type: 'warning' },
  { value: '5', label: '已取消', type: 'warning' },
]
export const ndgsStatus = [
  { value: '1', label: '申請中', type: 'warning' },
  { value: '2', label: '已確認公司名稱', type: 'success' },
  { value: '3', label: '已完成', type: '' },
  { value: '4', label: '已取消', type: 'info' }
]

export const activityStatus = {
  0: '未參加',
  1: '已參加',
  2: '已報名活動',
}
// 货币類型
// export const contributionType = [
//   { value: '0', label: '貨幣' },
//   { value: '1', label: '實體' },
//   { value: '2', label: '智慧財產' },
//   { value: '3', label: '土地使用權' },
//   { value: '4', label: '勞務及信用出資' }
// ]

export const statusList = {
  1: '申請中',  // 內地公司
  2: '已確認公司名稱',
  3: '已完成',
  4: '已取消',
  5: '申請中', // 辦理掛靠
  6: '已完成',
  7: '申請中', // 記賬報稅
  8: '申請中',
  9: '申請成功',
  10: '放棄申請',
  11: '已完成',
  12: '等待用戶資料',
  13: '申請中', // 待辦創業補助申請
  14: '聯絡客戶中',
  15: '放棄申請',
  16: '已完成',
  17: '跟進中', // 企業咨詢顧問
  18: '未查看',
  19: '已完成',
  20: '申請中', // 香港BUD
  21: '申請中',
  22: '待提交',
  23: '提交完成',
  24: '放棄申請',
  25: '等待審批',
  26: '已簽約',
  27: '已付款',
  28: '已完成',
  29: '申請中', // 推薦開設跨境商業
  30: '已開設',
  31: '已收費', // 經營場地租賃
  32: '未收費',
  33: '已完成',
  34: '跟進中', // 品牌推薦
  35: '未查看',
  36: '已完成',
  37: '跟進中', // 其他科技產品
  38: '未查看',
  39: '已完成',
  40: '待確認地址', // 經營場地租賃
  41: '申請中', // 待辦創業補助申請
  42: '申請中', // 品牌推薦
  43: '申請中', // 其他科技產品
  44: '駁回', // 香港BUD
  45: '待支付', // 香港BUD
  46: '已撥款', // 香港BUD
}

