<script setup>
import {computed, onMounted, ref} from 'vue'
import {useRouter} from "vue-router";

const router = useRouter()

const formData = ref({})

import {ElMessage, ElMessageBox} from "element-plus";

//引用i18n
import {useI18n} from 'vue-i18n'
const {t} = useI18n()
// component
import syButton from '@/components/syButton/index.vue'
import syUpload from '@/components/SY_upload/index.vue'
// api
import {getHKCompanies, storageHKCInformation, addHKC, updateHKCInfo, getHKCDetails} from "@/api/hkCompany";
import {emailValidator} from "@/utils/global";

const rules = ref({
  hkcName: [{required: true, message: t('g.input') + t('serve.hkName'), trigger: 'blur'}],
  hkcEmail: [{required: true, validator: emailValidator, trigger: 'blur'}],
  brFile: [{required: true, message: t('g.upload') + 'BR' + t('serve.file'), trigger: 'change'}],
  brNumber: [{required: true, message: t('g.input') + 'BR' + t('serve.number'), trigger: 'blur'}],
  brExpirationTime: [{required: true, message: t('g.input') + 'BR' + t('serve.expirationTime'), trigger: 'blur'}],
})
let id = ref('')
onMounted(async () => {
  id.value = router.currentRoute.value.query.id
  if (id.value) {
    await getHKCDetailsData(id.value)
    // 駁回后需重新存儲當前表單信息
    let apiData = {
      json: JSON.stringify(formData.value),
      companyId: formData.value.hkcId,
    }
    await storageHKCInformation(apiData)
  } else {
    //
    await getProgressData()
  }

})

// 获取当前表单信息
const getProgressData = async () => {
  if (id.value) {
    await getHKCDetailsData(id.value)
  } else {
    let {data} = await getHKCompanies()
    if (data) formData.value = data
  }

}
const getHKCDetailsData = async (id) => {
  let { data } = await getHKCDetails(id)
  if (data) formData.value = data
}
// 提交
const processFormRef = ref()
const onsubmit = async (isSave) => {
  let apiData = {
    json: JSON.stringify(formData.value),
    companyId: formData.value.hkcId,
  }
  if (isSave) {
    // 保存
    let { messageCode } = await storageHKCInformation(apiData)
    ElMessage.success(t(`langCode.${messageCode}`))
    await getProgressData()
  } else {
    // 提交
    processFormRef.value.validate(async (v, invalidFields) => {
      if (v) {
        try {
          ElMessageBox.confirm(
              t('tips.isSubmit'),
              t('g.tips'),
              {
                confirmButtonText: t('g.sure'),
                cancelButtonText: t('g.cancel'),
                type: 'warning',
              }
          ).then(async () => {
            //编辑
            if (id.value) {
              formData.value.companyId = id.value
              let {messageCode} = await updateHKCInfo({...formData.value})
              ElMessage.success(t(`langCode.${messageCode}`))
              await router.push(`/hkCompany/hkCompanyList`)
            } else {
              //添加
              formData.value.companyId = formData.value.hkcId
              delete formData.value.hkcId
              let {messageCode} = await addHKC({...formData.value})
              ElMessage.success(t(`langCode.${messageCode}`))
              await router.push(`/hkCompany/hkCompanyList`)
            }

          }).catch(() => {
          })

        } catch (e) {
        }
      } else {
        // 提示校验错误信息
        for (let i in invalidFields) {
          ElMessage.warning(invalidFields[i][0].message)
          return
        }
      }
    })
  }
}
</script>

<template>
  <div>
    <div v-if="id" class="mt-3 mb-3" style="color: #6ACDC6">{{ $t('tips.hadReject') }}： {{ formData.dismissMessage }}</div>
    <div class="form-bg mt-md-5 p-4 p-md-5 hkAdd">
      <!--   驳回内容     -->
      <h2 class="bold700">{{ $t('top.addHKC') }}</h2>
      <el-form ref="processFormRef" :show-message="false" class="mt-4 mb-4" :model="formData" :rules="rules">
        <div class="row">
          <div class="col-md-6 col-12">
            <el-form-item prop="hkcName">
              <div class="form-floating w100">
                <input v-model="formData.hkcName" class="form-control" :placeholder="$t('serve.hkName')+'*'"/>
                <label>{{ $t('serve.hkName') }}*</label>
              </div>
            </el-form-item>
          </div>
          <div class="col-md-6 col-12">
            <el-form-item prop="hkcEmail">
              <div class="form-floating w100">
                <input v-model="formData.hkcEmail" class="form-control" :placeholder="$t('serve.hkcEmail')+'*'"/>
                <label>{{ $t('serve.hkcEmail') }}*</label>
              </div>
            </el-form-item>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6 col-12">
            <el-form-item prop="brFile" required>
              <div class="d-flex align-items-center itemBox">
                <span class="me-3">{{ $t('serve.upload') }}BR</span>
                <syUpload v-model:imgUrl="formData.brFile" :file-name="'brFile_' + formData.hkcId"
                          url="/file/getHKCFileUrl" fieldName="brFile"
                          :post-data="{ brFile: 'userHkCompany/brFile/' }"/>
              </div>
            </el-form-item>
          </div>
          <div class="col-md-6 col-12">
            <el-form-item prop="ciFile">
              <div class="d-flex align-items-center itemBox">
                <span class="me-3">{{ $t('serve.upload') }}CI</span>
                <syUpload v-model:imgUrl="formData.ciFile" :file-name="'ciFile_' + formData.hkcId"
                          url="/file/getHKCFileUrl" fieldName="ciFile"
                          :post-data="{ ciFile: 'userHkCompany/ciFile/' }"/>
              </div>
            </el-form-item>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-12">
            <el-form-item prop="brNumber" required>
              <div class="form-floating w100">
                <input type="number" v-model="formData.brNumber" class="form-control" placeholder="BR號碼*"/>
                <label>BR{{ $t('serve.number') }}*</label>
              </div>
            </el-form-item>
          </div>
          <div class="col-md-6 col-12">
            <el-form-item prop="brExpirationTime" required>
              <div class="d-flex align-items-center itemBox">
                <span style="width: 90px">BR{{ $t('serve.expirationTime') }}</span>
                <el-date-picker v-model="formData.brExpirationTime"
                                class="w100"
                                type="datetime" value-format="YYYY-MM-DD HH:mm"
                                format="YYYY-MM-DD HH:mm" placeholder=" "/>
              </div>
            </el-form-item>
          </div>
        </div>
        <div class="d-flex">
          <sy-button class="mt-4" @click="onsubmit(true)" base-color="#106A88" font-color="#fff">{{ $t('serve.save') }}</sy-button>
          <sy-button class="mt-4" @click="onsubmit(false)">{{ $t('g.submit') }}<i class="iconfont icon-fangxiang2-youbian"></i>
          </sy-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<style scoped lang="scss">

.el-form-item .itemBox {
  width: 100%;
  background: #FFFFFF66;
  border-radius: 10px;
  padding: 13px 10px;
}
::v-deep .hkAdd .el-form .el-input{
  background: none;
}
::v-deep .el-date-editor .el-input__wrapper {
  box-shadow: none;

  &:hover {
    box-shadow: none;
  }
}
</style>
