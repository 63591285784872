
export default {
    path: "/dbcybtsq",
    name: "dbcybtsq",
    redirect: "/dbcybtsq/progressList",
    // meta: { title: '代辦創業補助申請' },
    component: () => import("@/views/serve/dbcybtsq"),
    children: [
        // 同意须知
        {
            path: "agreen",
            name: "dbcybtsqAgreen",
            component: () => import("@/views/serve/dbcybtsq/agreen"),
            // meta: { title: '服務協議' }
        },
        // 注册步骤
        {
            path: "progressForm",
            name: "dbcybtsqProgressForm",
            component: () => import("@/views/serve/dbcybtsq/progressForm"),
        },
        // 进度列表
        {
            path: "progressList",
            name: "dbcybtsqProgressList",
            component: () => import("@/views/serve/dbcybtsq/progressList"),
        },
    ]
}

