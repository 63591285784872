import axios from "axios";
import { ElMessageBox, ElMessage } from "element-plus";
import router from "@/router/index";

import eventBus from "@/utils/bus";

import i18n from "@/lang/index";
const { t } = i18n.global;

// create an axios instance
const service = axios.create({
  // baseURL: "/api/user", // url = base url + request url
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000, // request timeout
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    let token = sessionStorage.getItem("TOKEN");
    // 自动刷新
    let currentTimestamp = new Date().getTime();
    let loginTimestamp = sessionStorage.getItem("LOGIN_TIMESTAMP");
    let USER_INFO = sessionStorage.getItem("USER_INFO");
    let time_diff = currentTimestamp - loginTimestamp;
    // 剩余5分钟过期，自动刷新token 5min = 300000mm
    // console.log(time_diff)
    if (time_diff <= 300000) {
      // token过期时间接近，刷新当前token
      // axios.get(`${process.env.VUE_APP_BASE_API}/user/refreshtoken?Authorization=${token}`).then(({data}) => {
      //
      // })
    }
    config.headers["Login-Type"] = "pc";
    config.headers["ngrok-skip-browser-warning"] = "true";

        // do something before request is sent
        if (token) {
            config.headers['Token'] = token
        }
        return config
    },
    error => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        const res = response.data
        // if the custom code is not 20000, it is judged as an error.
        if (res.code !== 200) {
            //清除通知定时任务
            eventBus.emit('closeRead', true)
            if (res.code === 401 || res.code === 407) {
                sessionStorage.removeItem("USER_INFO"); //删除本地
                sessionStorage.removeItem('TOKEN') //删除本地
                ElMessageBox.confirm(
                    res.code === 401 ? t(`top.loginFailure`) || '' : t(`langCode.${res.messageCode}`) || '',
                    t(`top.logOut`) || '', {
                        confirmButtonText: t(`top.reLogin`),
                        showCancelButton: false,
                        type: 'warning'
                    }).then(() => {
                    location.reload()
                }).catch(e => {
                })
            }else {
                ElMessage.error(t(`langCode.${res.messageCode}`) || 'Error');
            }
        } else {
            return res
        }
    },
    error => {
        let {response} = error
        //清除通知定时任务
        eventBus.emit('closeRead', true)
        if (response) {
            const {status, statusText} = response
            // 401
            if (status == 401 || status == 405 || status == 403) {
                sessionStorage.removeItem("USER_INFO"); //删除本地
                sessionStorage.removeItem("TOKEN"); //删除本地
                sessionStorage.removeItem("LOGIN_TIMESTAMP"); //删除本地
                sessionStorage.removeItem("REFRESH_TOKEN"); //删除本地
                ElMessageBox.confirm(
                    '登錄已過期，請重新登錄',
                    t('g.tips'),
                    {
                        confirmButtonText: t('g.sure'),
                        showCancelButton: false,
                        type: 'warning',
                    }
                ).then(() => {
                    router.push('/login')
                    eventBus.emit("setUserInfo", true)
                })
            } else {
                return Promise.reject(error)
            }
        } else {
            ElMessage.error('請求超時')
        }
        return Promise.reject(error)
    }
)

export default service
