import request from '@/utils/request'

// 获取未读条数
export function getInformUnreadCount() {
    return request({
        url: `/inform/getInformUnreadCount`,
        method: 'get'
    })
}

// 获取用户通知列表
export function getInformList(params) {
    return request({
        url: `/inform/getInformList`,
        method: 'get',
        params
    })
}

// 更新通知状态为已读
export function updateInformStatusRead(id) {
    return request({
        url: `/inform/updateInformStatusRead?id=${id}`,
        method: 'put'
    })
}

//获取最新消息列表
export function getNewListData(params) {
    return request({
        url: `/public/newMessage/list`,
        method: 'get',
        params
    })
}

//通过id获取最新消息详情
export function getNewMessageDetailsById(id) {
    return request({
        url: `/public/newMessage/getNewMessageDetailsById/${id}`,
        method: 'get'
    })
}

//获取全部分类
export function getTypeListData() {
    return request({
        url: `/public/newMessage/typeList`,
        method: 'get'
    })
}

//获取全部標簽
export function getTagListData() {
    return request({
        url: `/public/newMessage/tagList`,
        method: 'get'
    })
}

//获取所有最新消息id
export function getNewMessageIdList() {
    return request({
        url: `/public/newMessage/getNewMessageIdList`,
        method: 'get'
    })
}
