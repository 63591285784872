import { createStore } from "vuex";

export default createStore({
  state: {
    userInfo: {},
    userAvatar: null,
  },
  getters: { getUserAvatar: (state) => state.userAvatar },
  mutations: {
    setUserInfo(state, userInfo) {
      state.userInfo = userInfo;
    },
    setUserAvatar(state, avatar) {
      state.userAvatar = avatar;
    },
  },
  actions: {},
  modules: {},
});
