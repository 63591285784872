module.exports = {
    // 消息提示码
    langCode: {
        '1001': '請輸入密碼',
        '1002': '請輸入郵箱',
        '1003': '電郵格式不正確,請輸入正確電郵地址',
        '1004': '當前用戶已存在',
        '1005': '不能夠將Admin全部刪除',
        '1007': '超級管理員不能被刪除',
        '1008': '此管理員不存在或已被禁用',
        '1009': '登錄成功',
        '1010': '賬號密碼錯誤,或郵箱未驗證',
        '1011': '請輸入暱稱！',
        '1012': '用戶名格式不正確！',
        '1013': '請輸入姓名！',
        '1014': '請輸入電郵地址！',
        '1015': '電郵格式不正確,請輸入正確電郵地址！',
        '1016': '請輸入密碼',
        '1017': '請輸入8位或以上字元的密碼！',
        '1018': '請輸入電話號碼！',
        '1019': '電話號碼格式不正確！',
        '1020': '名稱已經有用戶選取，請註冊另一個名稱',
        '1021': '註冊成功',
        '1022': '註冊失敗',
        '1023': '電郵已經有用戶選取，請註冊另一個電郵',
        '1024': '登錄失敗',
        '1025': '令牌為空',
        '1026': 'token 資訊驗證失敗',
        '1027': 'token 已過期或驗證不正確！',
        '1028': 'refreshToken 無效',
        '1029': '請輸入賬號或密碼',
        '1030': '退出成功',
        '1031': '退出失敗',
        '1032': '用戶未登錄',
        '1033': 'accessToken 無效',
        '1034': '您沒有權限訪問',
        '1035': '請填寫表格必要內容',
        '1036': '不能禁用當前admin',
        '1037': '不能刪除當前admin',
        '1038': '權限變更請重新登陸',
        '1039': '用戶不存在',
        '1040': '重置密碼鏈接已過期',
        '1041': '信息補充成功',

        '2001': '香港公司不存在',
        '2002': '公司不存在',
        '2003': '公司名稱已存在',

        '3001': '發送成功',
        '3002': '發送失敗',
        '3003': '請輸入姓氏',
        '3004': '請輸入名字',
        '3005': '請輸入郵箱地址',
        '3006': '電郵格式不正確,請輸入正確電郵地址',
        '3007': '請選擇行業',
        '3008': '請勾選或取消訂閱最新咨詢選項',
        '3009': '請選擇您是否是會員',
        '3010': '訂閱成功',
        '3011': '訂閱失敗',

        '4001': '當前用戶或活動不存在',
        '4002': '簽到成功',
        '4003': '簽到失敗',
        '4004': '當前活動已經報名',
        '4005': '報名成功',
        '4006': '報名人數已爆滿',

        '5001': '郵件已發送',
        '5002': '用戶正在修改密碼',
        '5003': '發送失敗',
        '5004':	'发送失败!',
        '5005':	'請前往你的郵箱驗證!',
        '5006':	'驗證链接已过期!',
        '5007':	'驗證成功！',
        '5008':	'驗證失败！',
        '5009':	'已发送注册驗證信息，請前往郵箱查看！',

        '6001': '分配成功',
        '6002': '分配失敗',
        '6003': '確認成功',
        '6004': '確認失敗',
        '6005': '續費成功',
        '6006': '續費失敗',
        '6007': '沒有權限',
        '6008': '當前服務不可用',
        '6009': '服務已被使用或已進入註冊流程',
        '6010': '當前服務已被使用',
        '6011': '當前還沒有分配合作商戶',
        '6012': '服務已加入購物車',

        '7001': '添加成功',
        '7002': '添加失敗',
        '7003': '刪除成功',
        '7004': '刪除失敗',
        '7005': '修改成功',
        '7006': '修改失敗',
        '7007': 'token已失效',
        '7008': '上傳成功',
        '7009': '上傳失敗',
        '7010': '操作成功',
        '7011': '操作失敗',
        '7012': '提交成功',
        '7013': '提交失敗',
        '7014': '訂單創建成功',
        '7015': '當前沒有選擇下單項目',

        '8001': '當前父節點不存在',
        '8002': '請輸入必要內容',
        '8003': '標籤不存在',
        '8004': '當前標籤下還有其他子標籤，刪除失敗！',
        '8005': '當前標籤名字重複',
    },
    g: {
        free: '免費',
        forgotPassword: '忘記密碼',
        pw: '密碼',
        home: '首頁',
        login: '登錄',
        register: '註冊',
        about: '關於我們',
        contact: '聯絡我們',
        close: '關閉',
        serve: '服務',
        news: '最新消息',
        problem: '常見問題',
        search: '搜索',
        zh: '中文簡體',
        ft: '中文繁體',
        en: '英文',
        notice: '通知',
        activity: '活動動態',
        subscribeToUs: '訂閲我們',
        relatedService: '相關服務',
        subscribeNow: '立即訂閱',
        email: '電郵',
        cEmail: '公司電郵地址',
        clause: '隱私權政策條款及細則',
        clause2: '服務協議-條款及細則',
        back: '返回',
        sure: '確認',
        cancel: '取消',
        WStips: '溫馨提示',
        view: '查看',
        none: '暫無',
        uploadSuc: '上傳成功',
        uploadErr: '上傳失敗',
        imgCropping: '圖片裁剪',
        lName: '姓氏',
        fName: '名字',
        profession: '選擇行業',
        detailContent: '詳細查詢內容',
        subscribeNews: '訂閱我們最新資訊',
        yes: '是',
        no: '否',
        submit: '提交',
        year: '年份',
        sort: '分類',
        tag: '標簽',
        select: '請选择',
        input: '請輸入',
        upload: '請上傳',
        status: '狀態',
        action: '操作',
        edit: '編輯',
        tips: '提示',
        type: '類型',
        area: '面積',
        detail: '詳情',
        date: '日期',
        address: '地址',
        next: '下一步',
        last: '上一步',
        actionSuc: '操作成功',
        pending: '待定',
        imgLoading: '圖片加載中...',
        all: '全部',
        iCategory: '行業類別',
        settledArea: '落戶地區',
        settlementDate: '落戶日期',
        ourService: '本司服務',
        catering: '餐飲',
        stDevelopment: '科技開發',
        cosmeticTrade: '化妝品貿易',
        wholesale: '批發',
        successCase: '成功案例',
        modification: '修改',
        save: '保存',
        modifySuccessfully: '修改成功',
        nickname: '暱稱',
        toShop: '跳轉至購物車繼續購買',
        pageToLast: '跳轉至上一頁',
        pageJump: '立即跳轉',
        select1: '選擇',
        serviceName: '服務名稱',
        price: '單價',
        checkOut: '前往結賬',

    },
    // 提示词
    tips: {
        numberTips: '请输入正确的数值',
        activePayed: '成功支付後，該活動頁面將出現【掃碼簽到】的二維碼，請截圖保存，用於當日簽到之用。',
        serverPayed: '可前往購買之服務界面>點擊【使用服務】開始申請',
        wait: '正在生成收費鏈接，請稍等...',
        payAc: '當前活動為付費活動，是否前往付費？',
        addCName: '請提供公司名',
        selectOrder: '請選擇下單項目',
        shopRemove: '是否移除購物車',
        payFail: '抱歉,訂單支付失敗',
        paySuc: '支付成功',
        userNickname: '請輸入用戶昵稱',
        hadSignUp: '當前活動已經報名，可以到活動報名記錄查看',
        noLogin: '如果您還沒有帳號，請先註冊，註冊費用全免',
        signLogin: '請先登入以報名活動',
        houseCodeTips: '需提供25位房屋編號',
        formNotCompleted: '表單尚未完成',
        continuePay: '服務即將完結時我們將提示續費',
        cAmountTips: '請输入至少一项出資金額',
        addressTips: '必須是內地人，地址同身份證上地址相同',
        phoneTips: '必須為內地11位手機號碼，171手機開頭不可使用',
        selectImg: '選擇本地圖片',
        emailNull: '請輸入郵箱地址',
        emailError: '電郵格式不正確,請輸入正確電郵地址',
        imgSize: '上傳图片大小不能超过 10MB',
        isVip: '您是會員嗎？',
        inputTip: '請輸入關鍵字',
        isAbandon: '是否確認放棄申請？',
        isHasMC: '是否有内地公司',
        checkNotice: '請勾选须知',
        isPay: '是否確認付費?',
        isSubmit: '是否確認提交?',
        unDevelopment: '請先填寫需求，聯絡我們。我們將有專人盡快聯繫你。',
        payFirst: '請先購買服務',
        unLogin: '您尚未進行登入,是否跳轉至登入頁面進行登入',
        isAddShop: '是否將該服務加入至購物車',
        registSubmit: '內地公司註冊過程已提交',
        addSubmit: '添加内地公司流程已提交',
        scaleLimit: '入股总比例不能大于100%',
        hadReject: '申請已被驳回',

    },
    // 首页
    home: {
        bannerTitle1: '立即啟動夢想，',
        bannerTitle2: '掌握大灣區機遇！',
        bannerText: '大灣區作為全中國經濟增長的核心，無論是科技、金融、餐飲、物流還是跨國企業都充滿無限機會。我們將提供專業指導，助你更快,更容易踏上成就的旅程。不要錯失這個獨一無二的機會，立即點擊查詢，開啟你的大灣區之路！',
        viewCase: '查看案例',
        freeSign: '立即免費登記',
        yzsTitle1: 'GBAEasy 為你提供',
        yzsTitle2: '一站式大灣區創業服務',
        learnMore: '了解更多',
        hddtTitle1: '如何掌握最新商業趨勢？',
        hddtTitle2: 'GBAEasy 活動動態',
        cgalTitle1: '有沒有參考個案？',
        cgalTitle2: '大灣區成功案例',
        hzhbTitle1: '有那些具實力合作伙伴？',
        hzhbTitle2: '香港商業合作伙伴',
        gfdwTitle1: '大灣區支援',
        gfdwTitle2: '內地合作官方單位',

    },
    com: {
        uploading: '上傳'
    },
    // 側邊欄和頂部
    top: {
        backToHome: '回到主頁',
        logOut: '退出登錄',
        welcome: '歡迎使用本系統',
        system: '灣區易',
        login: '登入',
        loginFailure: '登錄已失效',
        reLogin: '重新登錄',
        myInfo: '個人資訊',
        addMC: '新增内地公司',
        mcList: '内地公司列表',
        addHKC: '新增香港公司',
        hkList: '香港公司列表',
        supplierLink: '开通供应商入口',
        partnerLogin: '合作商戶登入',
        signRecord: '活動報名記錄',
        shoppingCart: '購物車',
        company: '公司',
        shop: '商鋪',
        createDate: '創建時間',
        systemMessages: '系统消息',
    },

    // 服务相关
    serve:{
        title: '一站式大灣區企業服務',
        agreeC: '同意並繼續',
        confirmS: '確認並提交',
        continue: '繼續',
        HKHO: '香港總公司',
        hkc: '香港公司',
        SZBranch: '深圳分公司',
        ndgszc: '內地公司註冊',
        hkcDetail: '香港公司詳情',
        hkAddress: '香港九龍長沙灣長沙灣道650號中國船舶大廈6樓603室',
        szAddress: '廣東省深圳市寶安區華豐國際機器人產業園C棟2樓C221室',
        blhb: '辦理紅本',
        hbmd: '紅本類型',
        hbft: '紅本（正面）',
        hbmd1: '以租辦公室出紅本註冊公司',
        hbmd2: '以掛靠形式出紅本註冊公司',
        region: '區域',
        idealPosition: '理想位置',
        lpName: '法人姓名',
        sir: '先生',
        woman: '女士',
        appellation: '稱謂',
        mphone: '內地手機號',
        lpIdNo: '法人回鄉證號碼',
        idNoFront: '回鄉證（正面）',
        idNoBack: '回鄉證（背面）',
        save: '儲存',
        bhNo: '回鄉證號碼',
        idealCompany: '理想公司',
        submitting: '待交表格中',
        dbcy: '代辦創業補助申請',
        mCompany: '内地公司',
        projectName: '項目名稱',
        subsidyType: '補貼類型',
        socialPeriod: '社保年限',
        socialCertificate: '社保憑證（PDF）',
        socialCertificate2: '社保憑證',
        applicationDate: '申請日期',
        abandonApplication: '放棄申請',
        jycd: '經營場地租賃',
        idealLocation: '具體理想位置',
        scopeOfOperation: '營運範圍（類別）',
        scopeOfOperation2: '營運範圍',
        companyType: '公司類型',
        companyArea: '公司面積',
        progressDetails: '進度詳情',
        officeAddress: '具體辦公場地地址',
        confirmAddress: '确认地址',
        cName: '公司名稱',
        cPhone: '公司電話',
        alternateName: '後備公司名稱',
        hkName: '香港公司名稱',
        cEmail: '公司郵箱',
        hkcEmail: '香港公司郵箱',
        leaseContract: '租约合同',
        unknownContract: '其他合同（圖片）',
        jzbs: '記賬報稅',
        hkPhone: '香港手機號',
        hkPhoneError: '香港手機號碼格式不正確,請輸入正確手號碼',
        mPhone: '内地手機號',
        leaseAgreement: '租約合約（圖片）',
        lpPhone: '法人内地手機號碼',
        lp: '法人',
        lpEmail: '法人電子郵箱',
        lpAddress: '法人住址',
        quarterAccounts: '季度公賬',
        yearAccounts: '年度公賬',
        quarter: '季度',
        uploadPublicAccount: '上傳公賬',
        month: '月份',
        uploadTime: '上傳時間',
        uploaded: '已上傳',
        notUpload: '未上傳',
        question: '問題',
        pptg: '品牌推廣',
        questionFilling: '問題填寫',
        qtkjcp: '其他科技產品',
        qyzxgw: '企業咨詢顧問',
        isFundsHK: '資金需不需返回香港中銀內地戶為主',
        stockholder: '股東',
        businessLicense: '營業執照',
        mAddressContract: '內地公司地址合約',
        tjks: '推薦開設商業公司銀行内地賬戶',
        phone: '聯繫電話',
        phone2: '電話號碼',
        brFile: 'BR文件',
        ciFile: 'CI文件',
        auditReport: '核数报告',
        nar1: 'NAR1',
        mpf: 'MPF',
        monthFile: '3個月文件',
        bankStatment: '公司银行对账单',
        expenditureBudget: '開支及預算表',
        prospectus: '計劃書',
        hkBUD: '香港區BUD',
        rejectContent: '驳回内容',
        fileDeadline: '文件截止日期',
        fulPaymentData: '成功回款数据',
        projectReport: '項目報告',
        pay: '付費',
        submitDate: '提交日期',
        serviceIntro: '服務介紹',
        mustKnow: '準備及須知',
        purchaseService: '購買服務',
        serve: '服務',
        theme: '服務主題',
        charge: '收費',
        time: '時間',
        useService: '使用服務',
        serviceRenewal: '服務續費',
        progressList: '進度列表',
        confirmationData: '確認資料',
        confirmCName: '確認公司名稱',
        rCertificate: '紅本租賃憑證',
        partialNail: '公司資料表格(甲部分)',
        director: '董事',
        name: '姓名',
        email: '電子郵箱',
        address: '住址',
        address1: '地址',
        cAddress: '公司地址',
        gManager: '總經理',
        supervisor: '監事',
        addressCoding: '公司地址編碼',
        serviceOfLD: '法律文件送達服務',
        LDDelivery: '法律文書送達人',
        idNo: '身份證號碼',
        delivery: '送達方式',
        express: '快遞寄送',
        pick: '自取',
        finance: '財務負責人員',
        taxes: '辦稅負責人員',
        rCapital: '註冊資本',
        wan: '（萬元）',
        partB: '（乙部分）',
        partC: '（丙部分）',
        partD: '（丁部分）',
        socialManage: '社保聯係負責人員',
        cManage: '公司指定聯絡負責人員',
        payOut: '出資',
        amountContribution: '出資額',
        amountWays: '出資方式',
        sRatio: '入股比例',
        serviceDetails: '服務詳情',
        cId: '公司ID',
        confirmName: '最后确认公司名稱',
        logUser: '登記用戶',
        isPlatform: '是否平台入驻',
        rejectInfo: '驳回原因',
        fillAddress: '填補地址',
        houseCode: '房屋编码',
        agencyLicense: '代辦營業執照',
        inscribeDate: '刻章日期',
        fillCName: '填寫公司名稱',
        cNameEx: `請以以下格式填寫：<br>
          ⼀、深圳市+字號+⾏業類型+有限公司（例⼦：深圳市數易資訊科技顧問有限公司）<br>
          ⼆、字號+⾏業類型+(深圳)+有限公司（例⼦：數易資訊科技顧問(深圳)有限公司）<br>
          三、字號+⾏業類型+深圳+有限公司（例⼦：數易資訊科技顧問深圳有限公司）`,
        fillRedForm: '請填寫紅本表格',
        lastCName: '這是於上一步已填寫好的公司名稱',
        fillCTable: '請填寫公司資料表格',
        directorsInfo: '董事資料',
        generalInfo: '總經理資料',
        isFillDirector: '是否董事人員信息填充至下方表格',
        isFillFinance: '是否將財務人員信息填充至後續表單中',
        supervisorInfo: '監事資料',
        flwsInfo: '法律文件送達資料',
        jyxmEx: '⼀般經營項⽬是：電⼦專⽤材料研發；新材料技術研 發；集成電路芯⽚設計及服務；集成電路芯⽚及產品 銷售；電⼦元器件批發；電⼦元器件零售，技術服務、 技術開發、技術咨詢、技術交流、技術轉讓、技術推 廣；物聯網技術服務；新材料技術推廣服務；信息咨 詢服務（不含許可類信息咨詢服務）；電⼦產品銷售； 電池銷售；⾳響設備銷售；可穿戴智能設備銷售；箱 包銷售；發電機及發電機組銷售；電⼦測量儀器銷售； 鐘錶與計時儀器銷售；鐘錶銷售；技術進出⼝；貨物 進出⼝；進出⼝代理。（除依法須經批准的項⽬外， 憑營業執照依法⾃主開展經營活動），許可經營項⽬ 是：無',
        fillStockholder: '請填寫入股比例資料',
        expirationTime: '到期时间',
        updateTime: '更新时间',
        number: '號碼',
        file: '文件',
        contactEmail: '聯絡郵箱',
        upload: '上傳',
        clickToFill: '點擊填充',

    },

    // 关于我们
    about:{
        t1: '灣區港人港企服務中心',
        t1_info1: '(Bay Area Hong Kong Enterprise Service Center Co.,Limited)',
        t1_info2: '灣區港人港企服務中心（Bay Area Hong Kong Enterprise Service Center Co.,Limited）成立於2021年，致力推動文化交流及企業拓展服務。',
        t1_info3: '公司服務以會員制推行，旨在構建互通共融的文化下，以不同的文化及商貿推廣活動促進大灣區一流城市的重心發展價值，讓企業深入了解大灣區不同地域文化及營商環境，充分運用不同城市的市場政策，為本地企業及跨國公司提供度身訂造全方位的拓展計劃，引領各行各業進駐大灣區市場。',
        t2: '大灣區品牌發展藍圖',
        t2_info1: '以邁向「粵港澳大灣區」作核心發展藍圖，利用不同省市之文化資源為企業規劃出線上線下的推廣宣傳策略，建立別樹一幟的企業文化，又啟發出創業家對品牌發展之核心價值。',
        t2_info2: '要打造品牌定位的優勢，將品牌名聲傳至世界各地，我們會以不同形式的團體及商貿推廣活動，務求令品牌取得理想的知名度，達至世界級水平的卓越領域。',
        t3: '我們的使命',
        t3_info1: '「提升文化共享新價值」',
        t3_info2: '互動文化，社會共融',
        t3_info3: '秉承「互動文化，社會共融」的共享文化理念，以創新資訊分析來領先市場規劃導向，配合目標性高的宣傳策略方案，將大灣區城市的文化特色及強勢發展融為一體。',
        t3_info4: '發揮最大的經濟效益',
        t3_info5: '以集合企業、社會力量來發揮最大的經濟效益，從而啟發我們利用一流的發展動向。',
        t3_info6: '著意令企業放眼世界',
        t3_info7: '注入多元文化與商貿社會於一身的元素而達至共贏效果，令各個品牌得以揚威海外，達至世界級水平。',
        t4: '我們的願景',
        t4_info1: '「構建文化命運共同體」',
        t4_info2: '因應各界對大灣區發展的需要，竭力打造與別不同的一流推廣策劃服務，目的是為企業品牌可以全方位接觸文化差異對業務拓展的至勝關鍵。透過我們的互動推廣平台：',
        card1_1: '可貫通社會共享文化',
        card1_2: '的創意營運模式',
        card1_3: '進一步為企業構建不同層次的非凡成就',
        card2_1: '建立共融文化',
        card2_2: '新時代',
        card2_3: '提升企業們於同一體系下走到世界的',
        card2_4: '最前端，為企業打好創業的基石',
        card3_1: '成就未來環球企業的',
        card3_2: '重心價值',
        card3_3: '實現高技能人才，',
        card3_4: '引領各行各業進駐大灣區市場',




    },

    // 活动
    activity: {
        t1: '由零開始，低風險完你的創業夢！開擴生意業務！快來報名吧！',
        date: '活動日期',
        location: '活動地點',
        organizer: '主辦單位',
        t2: '名額有限，額滿即止！',
        scanSignIn: '掃碼簽到',
        t3: '在活動當天，請將此二維碼展示給工作人員，掃碼後即可完成簽到。',
        signIn: '活動報名',
        tag: '標籤',
        shareTo: '分享至',
        last: '上一篇',
        next: '下一篇',
    },

    //常見問題
    problem: {
        title: '常見問題',
        t1: '希望這些常見問題解答對您有所幫助。',
        t2: '如果您有任何其他問題，請隨時聯繫我們。',
        q1: '大灣區包括哪些城市？',
        a1: '大灣區包括香港、澳門兩個特別行政區，和廣東省廣州、深圳、珠海、佛山、惠州、東莞、中山、江門、肇慶九市，所以成為「9+2」。',
        q2: '決定開立大灣區公司第一步？',
        a2_1: '實名認證的內地11位手機號碼，用於聯絡、短信驗證，一切申請表格的填寫。',
        a2_2: '大部分香港流動網絡服務營辦商容許客戶在香港申請實名認證的中國內地手機號碼。包括3 香港、CSL、中國移動香港、中國聯通 (香港)、 數碼通、 Sun Mobile等。',
        q3: '若想申請補貼，我的公司適合落戶在哪個區？',
        a3: '例：深圳市下轄10個行政區，每個區域的創業補貼行業限制可能有所不同。',
        q4: '成立外資企業基本流程有哪些？',
        a4: `1.決定建立的公司類型、公司名稱和營業地點 <br>
              2.名稱預先核准<br>
              3.準備一切所需文件：包括已註冊的公司名稱、地址、公司章程、註冊資本金額<br>
              4.向地方工商管理局註冊和申請營業執照<br>
              5.刻造公章、私章、財務章、發票章`,
        q5: '外資企業的註冊地址有何要求？',
        a5: '企業須有政府認可的經營場地地址，通常設於寫字樓、商住兩用樓、商務中心等商業辦公樓。',
        q6: '外資企業的法人代表由誰擔任？',
        a6: '外資企業的法人代表無國籍限制，可以由企業去委任為法定代表人。',

    },

    // login
    lg:{
        accountInfoComplete: '信息補充',
        realName: '真實姓名',
        sendEmail: '發送重設密碼電郵',
        backLogin: '返回登入頁面',
        resetTips: '我們收到了您對於重置密碼的請求。並把重置密碼連結已發送至你的電郵。',
        regist: '個人帳戶註冊',
        nickname: '暱稱',
        pwTips: '建立至少8位數字密碼',
        cPassword: '確定密碼',
        isAccount: '已有帳號？',
        isNotAccount: '還沒有帳號？',
        signInNow: '立即登入',
        sucRegist: '感謝您註冊成為我們的會員！為了確保您提供的電子郵件地址正確並屬於您本人，請登入你的電郵請點擊驗證連結以完成註冊。',
        emailVerify: '請前往你的郵箱驗證',
        accountLogin: '帳戶登入',
        inputEmail: '請輸入電郵地址',
        forgotPassword: '忘記密碼？',
        registerNow: '立即註冊',
        goNowSignIn: '立即前往登錄',
        reRegister: '重新前往註冊',

    },
    // 联系我们
    conUs: {
        lastName: '姓氏',
        firstName: '名字',
        phone: '聯絡電話/WhatsApp',
        labelId: '行業',
    }

}
