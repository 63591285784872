<script setup>
import { ref, defineProps, defineEmits } from 'vue'
const emit = defineEmits(['update:imgUrl'])
import { ElMessage } from 'element-plus'

//引用i18n
import { useI18n } from 'vue-i18n'
const { t } = useI18n()

import { getUploadUrl } from "@/api/global";
import axios from 'axios'

// components
import syPicView from "@/components/SY_PicView";

// props
const props = defineProps({
  accept: {
    type: String,
    default: '.jpg,.png,.jpeg,.pdf'
  },
  // 图片是否公共
  isPublic: {
    type: Boolean,
    default: false
  },
  imgUrl: {
    type: [String, Array],
    default: ''
  },
  url: '', // apiUrl
  fieldName: '', // 对应的图片字段
  postData: {
    type: Object,
    default: () => {
      return {}
    }
  },
  // 文件名
  fileName: '', // 自定义文件名称
})

let loading = ref(false)

// // 获取s3文件上傳路径
const handleChange = async (file) => {
  // 文件大小限制
  const isLt = file.size / 1024 / 1024 < 10;
  if (!isLt) {
    ElMessage.warning(t('tips.imgSize'));
    return false
  }

  // 上傳
  loading.value = true
  try {
    // 获取s3地址
    let { name, raw } = file
    // 若有文件名，则使用文件名， 则使用本身文件名 （为了减少s3资源浪费，再次上傳直接覆盖名称相同的图片）
    let fileType = name.split(".")[name.split(".").length - 1]
    name = props.fileName ? (`${props.fileName}.${fileType}`) : name
    // 设置文件在s3的地址
    props.postData[props.fieldName] += name
    // 获取s3地址
    let { data } = await getUploadUrl(props.url, props.postData)
    // 上傳s3
    await axios.put(data.url || data, raw)
    emit("update:imgUrl", props.postData[props.fieldName]);
    ElMessage.success(t('g.uploadSuc'))
  }
  catch (e) {
    ElMessage.error(t('g.uploadErr'))
  }
  loading.value = false
}

</script>

<template>
  <div class="uploadBox">
    <!--      图片查看       -->
    <syPicView :pic-url="imgUrl" :is-public="isPublic"></syPicView>
    <el-upload v-loading="loading" v-bind="$attrs" class="sy-upload" action="#" :accept="props.accept"
      :show-file-list="false" :auto-upload="false" :on-change="handleChange">
      <el-button type="primary" :text="true">{{ $t('com.uploading') }}</el-button>
    </el-upload>
  </div>
</template>

<style scoped lang="scss">
.uploadBox {
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  ::v-deep .el-loading-mask {
    background: rgba(255, 255, 255, 0.2) !important;
  }
}
</style>
