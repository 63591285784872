
export default {
    path: "/tjkskj",
    name: "tjkskj",
    redirect: "/tjkskj/progressList",
    // meta: { title: '推薦開設商業公司銀⾏內地帳⼾' },
    component: () => import("@/views/serve/tjkskj"),
    children: [
        // 同意须知
        {
            path: "agreen",
            name: "tjkskjAgreen",
            component: () => import("@/views/serve/tjkskj/agreen"),
            // meta: { title: '服務協議' }
        },
        // 注册步骤
        {
            path: "progressForm",
            name: "tjkskjProgressForm",
            component: () => import("@/views/serve/tjkskj/progressForm"),
        },
        // 进度列表
        {
            path: "progressList",
            name: "tjkskjProgressList",
            component: () => import("@/views/serve/tjkskj/progressList"),
        },
    ]
}

