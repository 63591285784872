
export default {
    path: "/blgk",
    name: "blgk",
    redirect: "/blgk/progressList",
    component: () => import("@/views/serve/blgk"),
    // meta: { title: '辦理紅本' },
    children: [
        // 同意须知
        {
            path: "agreen",
            name: "blgkAgreen",
            component: () => import("@/views/serve/blgk/agreen"),
            // meta: { title: '服務協議' }
        },
        // 注册步骤
        {
            name: "personalInfo",
            path: "personalInfo",
            component: () => import("@/views/serve/blgk/personalInfo"),
        },
        // 进度列表
        {
            path: "progressList",
            name: "blgkProgressList",
            component: () => import("@/views/serve/blgk/progressList"),
        },
    ]
}

