import {createRouter, createWebHashHistory, createWebHistory} from "vue-router";
import mainland from "./mainland";
import jycdzl from "./jycdzl";
import blgk from "./blgk";
import jzbs from "./jzbs";
import dbcybtsq from "./dbcybtsq";
import xgpBud from "./xgpBud";
import qyzxgw from "./qyzxgw";
import pptg from "./pptg";
import qtkjcp from "./qtkjcp";
import tjkskj from "./tjkskj";
import mainlandCompany from "./mainlandCompany";
import hkCompany from "./hkCompany";
import activity from "./activity";
import information from "./information";
import caseAssemble from "./caseAssemble";
import payCallBack from "./payCallBack";
import systemNews from './systemNews'
import news from './news'

const routes = [
    {
        path: "/login",
        name: "login",
        component: () => import("../views/login"),
        meta: {title: '登錄'}
    },
    {
        path: "/accountInfoComplete/:id",
        name: "accountInfoComplete",
        component: () => import("../views/login/accountInfoComplete"),
        meta: {title: '信息補充'}
    },
    {
        path: "/noticeThat",
        name: "noticeThat",
        component: () => import("../views/login/noticeThat"),
        // meta: { title: '條款及細則' }
    },
    {
        path: "/forgetPassword",
        name: "forgetPassword",
        component: () => import("../views/login/forget"),
        // meta: { title: '忘記密碼' }
    },
    {
        path: "/resetPassword",
        name: "resetPassword",
        component: () => import("../views/login/reset"),
        // meta: { title: '重設密碼' }
    },
    {
        path: "/registResult/:token",
        name: "registResult",
        component: () => import("../views/login/registResult"),
        // meta: { title: '驗證' }
    },
    {
        path: "/registPassword",
        name: "registPassword",
        component: () => import("@/views/login/regist"),
        // meta: { title: '個人帳戶註冊' }
    },
    //首页
    {
        path: "/",
        name: "home",
        component: () => import("../views/home"),
        // meta: { title: '首頁' }
    },
    // 關於我們
    {
        path: "/about",
        name: "about",
        component: () => import("../views/about"),
        // meta: { title: '關於我們' }
    },
    // 服务列表
    {
        path: "/serviceList",
        name: "serviceList",
        component: () => import("@/views/serve/serveList"),
        // meta: { title: '服務' }
    },
    // 服务详情信息
    {
        path: "/serveInfo",
        name: "serveInfo",
        component: () => import("@/views/serve/serveInfo"),
        // meta: { title: '服務詳情' }
    },

    // 购物车
    {
        path: "/shoppingCart",
        name: "shoppingCart",
        component: () => import("@/views/shoppingCart"),
        // meta: { title: '購物車' }
    },
    // 常見問題
    {
        path: "/commonProblem",
        name: "commonProblem",
        component: () => import("@/views/commonProblem"),
        // meta: { title: '常見問題' }
    },
    // 消息
    systemNews,
    news,

    /*------------------------服务-----------------------*/
    mainland,
    jycdzl,
    blgk,
    jzbs,
    dbcybtsq,
    xgpBud,
    qyzxgw,
    pptg,
    qtkjcp,
    tjkskj,
    /*------------------------个人账户（下拉功能）-----------------------*/
    mainlandCompany,
    hkCompany,
    /*------------------------个人账户（下拉功能）-----------------------*/
    activity,
    information,
    caseAssemble,

    /*支付相关*/
    payCallBack

];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        // 始终滚动到顶部
        return {top: 0}
    },
});

router.beforeEach((to, from) => {
    /* 路由发生变化,存儲當前頁面路由 (用于用户登录后，回到之前页面) */
    let loginPath = ['login', 'registPassword', 'forgetPassword', 'resetPassword', 'registResult']
    // 上一页页面若为登录相关，则不记录
    if(!loginPath.includes(from.name)){
        window.localStorage.setItem('routerBack', from.fullPath)
    }

    // 若当前存在活动自动登录标记， 跳转至非活动（id）页面时，清除活动自动登录标记
    let autoSignUpId = window.localStorage.getItem('autoSignUpId')
    if(autoSignUpId && !loginPath.includes(to.name) && to.path !== `/activity/detail/${autoSignUpId}`){
        window.localStorage.removeItem('autoSignUpId')
    }
})

export default router;
