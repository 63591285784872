<template>
  <headerNav></headerNav>
  <router-view />

  <!-- cookie接受 -->
  <acceptCookie></acceptCookie>

  <footerCont></footerCont>
</template>

<script setup>
import headerNav from './components/header'
import footerCont from './components/footer'
import acceptCookie from './components/acceptCookie'

import { watch } from "vue";
import { useRouter } from 'vue-router'
const router = useRouter()
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #01374F;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.icon-img {
  position: absolute;
  right: 10px;
  top: 18px;
}

.is-dark {
  background-color: #C3D5D5 !important;
  color: #01374F !important;
  border: 0px !important;

  .el-popper__arrow {
    &::before {
      background-color: #C3D5D5 !important;
      border: 0px !important;
    }
  }
}
</style>
