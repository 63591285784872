
export default {
    path: "/jycdzl",
    name: "jycdzl",
    redirect: "/jycdzl/progressList",
    // meta: { title: '經營場地租賃' },
    component: () => import("@/views/serve/jycdzl"),
    children: [
        // 同意须知
        {
            path: "agreen",
            name: "jycdzlAgreen",
            component: () => import("@/views/serve/jycdzl/agreen"),
            // meta: { title: '服務協議' }
        },
        // 注册步骤
        {
            path: "addSite",
            name: "addSite",
            component: () => import("@/views/serve/jycdzl/addSite"),
        },
        // 进度列表
        {
            path: "progressList",
            name: "jycdzlProgressList",
            component: () => import("@/views/serve/jycdzl/progressList"),
        },
        // 详情
        {
            path: "detail",
            name: "jycdzlDetail",
            component: () => import("@/views/serve/jycdzl/progressList/detail"),
        },
    ]
}

