
export default {
    path: "/pptg",
    name: "pptg",
    redirect: "/pptg/progressList",
    // meta: { title: '品牌推薦' },
    component: () => import("@/views/serve/pptg"),
    children: [
        // 同意须知
        {
            path: "agreen",
            name: "pptgAgreen",
            component: () => import("@/views/serve/pptg/agreen"),
            // meta: { title: '服務協議' }
        },
        // 注册步骤
        {
            path: "progressForm",
            name: "pptgProgressForm",
            component: () => import("@/views/serve/pptg/progressForm"),
        },
        // 进度列表
        {
            path: "progressList",
            name: "pptgProgressList",
            component: () => import("@/views/serve/pptg/progressList"),
        },
    ]
}

